































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
@Component
export default class NewTracerStudy extends Vue {
  private currentTab = 0;

  @Watch("$route", { immediate: true })
  private onRouteChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.currentTab = Number(this.$route.path.split("/").reverse().at(0)) - 1;
  }

  private images = [
    require("@/assets/Images/enterpreneur/1.png"),
    require("@/assets/Images/enterpreneur/2.png"),
    require("@/assets/Images/enterpreneur/3.png"),
  ];
  private effectiveImages = [
    require("@/assets/Images/enterpreneur/2.1.png"),
    require("@/assets/Images/enterpreneur/2.2.png"),
    require("@/assets/Images/enterpreneur/2.3.png"),
  ];
  private importantImages = [
    require("@/assets/Images/enterpreneur/3.1.png"),
    require("@/assets/Images/enterpreneur/3.2.png"),
    require("@/assets/Images/enterpreneur/3.3.png"),
  ];

  private scrollTo(selector: string) {
    document.querySelector(".MainLanding")?.scrollTo({
      top:
        Number(document.querySelector<HTMLDivElement>(selector)?.offsetTop) -
        90,
      behavior: "smooth",
    });
  }
}
